import OverlayLoading from "@Components/Loaders/OverlayLoading";
import RandomPhrases from "@Components/RandomPhrases";
import { useAuth } from "@Contexts/Auth";
import { Plano } from "@Services/Helpers";
import WebApi from "@Services/WebApi";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Componente de checkin em tempo real, remove a necessidade de
 * uma rota para obter os dados do usuário, melhora os relatorios
 * do clarity
 */

/**
 * ! IMPORTANTE !
 * Aqui deve ser configurado a regra de negócio de redirect de usuário;
 * De preferencia evitar gambiarras, criar handles para cada tipo de usuário
 * e configurar os redirects tomando cuidado com hierarquia de usuários
 */

const PERMISSOES = {
  MEUFIDELIZI: [
    "fidelizii-master",
    "fidelizii-gerente",
    "parceiro-loja-master",
    "parceiro-loja-gerente"
  ],
  CALCULADORA: ["fidelizii-calculadora"]
};

const GetUser = ({ children, redirect }) => {
  //////////////////////////////////////////////////////////////////////////////////////
  // Hooks
  //////////////////////////////////////////////////////////////////////////////////////
  const navigate = useNavigate();
  const location = useLocation();
  const {
    user,
    rede,
    isRede,
    isAuthenticated,
    isSuperAdmin,
    SetUser,
    SetRede,
    SetIntercom,
    Logout
  } = useAuth();

  //////////////////////////////////////////////////////////////////////////////////////
  // Requests
  //////////////////////////////////////////////////////////////////////////////////////
  function requestCurrentUser() {
    WebApi.v2
      .get("meu/administradores/current")
      .then(({ data }) => {
        const { acesso, user } = data;

        // Se o acesso enviado não existir ou não for um dos aprovados, retorna o logout
        if (!acesso || !["meufidelizi", "calculadora"].includes(acesso)) {
          return Logout({
            title: "Atenção",
            text: "O seu tipo de acesso não  pode ser identificado, contate o suporte técnico!",
            icon: "error"
          });
        }

        if (acesso === "meufidelizi") {
          handleMeuFideliziUser(user);
        }

        if (acesso === "calculadora") {
          handleCalculadoraUser(user);
        }
      })
      .catch((err) => {
        if (isAuthenticated) {
          // Se retornar 401, sessão expirada, retorna o logout
          if ([401].includes(err.status)) {
            return Logout(
              {
                title: "Oops!",
                text: "Sessão expirada, efetue login novamente para utilizar o sistema.",
                icon: "warning"
              },
              { from: location?.state?.from || location.pathname }
            );
          }

          // Se houver erro no endpoint ou der forbidden por algum motivo, retorna o logout
          if ([400, 403].includes(err.status)) {
            return Logout(
              {
                title: "Oops!",
                text: `Ocorreu um erro ao tentar efetuar o login.\n${err?.data?.errors?.[0] ||
                  err?.data?.message ||
                  "Erro não identificado."
                  }\nEfetue login novamente!`,
                icon: "warning"
              },
              { from: location?.state?.from || location.pathname }
            );
          }

          // Se ocorrer um erro no servidor, retorna o logout
          if ([500].includes(err.status)) {
            return Logout(
              {
                title: "Oops!",
                text: `Ocorreu um erro no servidor de login, contate o suporte técnico.`,
                icon: "warning"
              },
              { from: location?.state?.from || location.pathname }
            );
          }

          if (!err?.status) {
            return Logout(
              {
                title: "Oops!",
                text: `Ocorreu um erro no sistema de login do Meu Fidelizi, contate o suporte técnico.`,
                icon: "warning"
              },
              { from: location?.state?.from || location.pathname }
            );
          }

          // Apenas logout caso não for nenhum dos 2 acima e finge que nada aconteceu
          return Logout();
        }

        //Se não estiver autenticado, nem tem como chegar aqui, a menos que colocaram o checkin no lugar errado :)
        return navigate("/", {
          replace: true,
          state: { from: location?.state?.from || location.pathname }
        });
      });
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////////////
  /**
   * Ajusta os dados do Auth para configurações de usuário do meu fidelizi
   * @param {*} data
   * @returns
   */
  function handleMeuFideliziUser(data) {
    let user = { ...data };
    let rede = data?.parceiros || [];

    user.isSuperAdmin = ["fidelizii-master", "fidelizii-gerente"].includes(
      user.permissao
    );
    delete user.parceiros;

    SetRede(rede);
    SetUser(user);
    SetIntercom({
      userId: user.id_administrador,
      name: user.nome,
      email: user.email,
      createdAt: user.created_at,
      userHash: user.hash,
      avatar: {
        type: "avatar",
        imageUrl:
          user?.metadata?.avatar ||
          "https://fidelizii.s3.sa-east-1.amazonaws.com/image/wizard-profile.png"
      },
      companies: rede.map((item, index) => ({
        companyId: item.id_parceiro,
        createdAt: item.created_at,
        plan: Plano(item.tipo_plano),
        name: item.nome
      })),
      customAttributes: { plataforma: "Meu Fidelizi" }
    });

    return user;
  }

  /**
   * Ajusta os dados do Auth para configurações de usuário da calculadora
   * @param {*} data
   * @returns
   */
  function handleCalculadoraUser(data) {
    let user = { ...data };
    SetUser(user);
    SetIntercom({
      userId: user.id_administrador,
      name: user.nome,
      email: user.email,
      customAttributes: { plataforma: "Calculadora" }
    });

    return user;
  }

  /**
   * Efetua os redirects do checkin se necessário
   * @returns
   */
  function handleRedirect() {
    console.log(redirect);
    console.log("handleRedirect called");
    // Se essa função rodar por algum motivo misterioso e redirect for false, faz nada;
    if (!redirect) {
      return console.log("handleRedirect canceled");
    }

    // Se o usuário estiver desabilitado, logout
    if (user.ativo === 0) {
      return Logout({
        title: "Atenção",
        text: "O seu acesso foi desativado!",
        icon: "error"
      });
    }
    console.log("user ativo === 0 passed");
    /**
     * Se houver um redirect que foi acesso direto de uma URL,
     * tenta a sorte e redireciona, máximo que pode acontecer é o usuário ser mandado pro
     * checkin novamente sem redirect e ir pra home do acesso :)
     */
    if (typeof redirect !== "boolean" && redirect !== "" && redirect !== "/") {
      return navigate(redirect, { replace: true });
    }
    console.log("type redirect === string passed");

    if (PERMISSOES.CALCULADORA.includes(user.permissao)) {
      return navigate("/contratacao", { replace: true });
    }
    console.log("calc passed");

    if (PERMISSOES.MEUFIDELIZI.includes(user.permissao)) {
      // Se for superAdmin
      if (isSuperAdmin) {
        return navigate(`/dashboard/31`, { replace: true });
      }
      console.log("isSuperAdmin passed");

      // Se houver mais de um acesso
      if (isRede) {
        return navigate("/rede", { replace: true });
      }
      console.log("isRede passed");

      // Se não cair em nada, é usuário comum e vai para o seu 1o estabelecimento da lista de estabelecimentos
      return navigate(`/dashboard/${rede[0].id_parceiro}`, { replace: true });
    }
    console.log("meu fidelizi passed");

    // Se vier parar aqui, vamos deslogar o usuário, sei la que tipo de acesso é, nem era pra ter chego aqui!
    return Logout({
      title: "Atenção",
      text: "O seu tipo de acesso não  pode ser identificado, contate o suporte técnico!",
      icon: "error"
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    // If pra não ficar chamando request repetidamente, caso vier dar problema, remover.
    if (!user) {
      return requestCurrentUser();
    }

    // Quando houver usuário e redirect for definido; redirect
    if (user && !!redirect) {
      return handleRedirect();
    }
  }, [user]);

  //////////////////////////////////////////////////////////////////////////////////////
  // Render
  //////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {!user && (
        <>
          <OverlayLoading><RandomPhrases.Usuario /></OverlayLoading>
        </>
      )}
      {user && children}
    </>
  );
};

export default React.memo(Object.assign(GetUser, { displayName: "GetUser" }));
