const phrases = [
  "🏇 Cavalgando até o seu estabelecimento...",
  "🚀 Indo de foguete até o seu estabelecimento...",
  "🧙‍♂️ Teletransportando até o seu estabelecimento...",
  "🐉 Voando com dragões até o seu estabelecimento...",
  "🧙‍♀️ Passando pelo portal mágico até o seu estabelecimento...",
  "🛸 Voando em uma nave espacial até o seu estabelecimento...",
  "🏄‍♂️ Surfando até o seu estabelecimento...",
  "🦸‍♂️ Super-herói indo até o seu estabelecimento...",
  "🧞‍♂️ Levando seu estabelecimento no tapete mágico...",
  "🧚‍♂️ Fada entregando suas informações ao seu estabelecimento...",
  "🧙‍♂️ Usando feitiço 'Aparecium Estabelecimento'...",
  "🧝‍♂️ Elfos entregando informações no seu estabelecimento...",
  "🧜‍♀️ Nadando até o seu estabelecimento...",
  "🚁 Helicóptero mágico até o seu estabelecimento...",
  "🧙‍♂️ Montando um grifo até o seu estabelecimento...",
  "🚤 Navegando pelos sete mares até o seu estabelecimento...",
  "🧞‍♀️ Gênio voando até o seu estabelecimento...",
  "🧛‍♂️ Vampiro entregando à meia-noite no seu estabelecimento...",
  "🦸‍♀️ Mulher-maravilha indo até o seu estabelecimento...",
  "🐉 Dragão cuspindo fogo até o seu estabelecimento...",
  "🧙‍♀️ Feitiço de bruxa para chegar ao seu estabelecimento...",
  "🚀 Navegação estelar até o seu estabelecimento...",
  "🧚‍♀️ Fada madrinha enviando ao seu estabelecimento...",
  "🧛‍♀️ Vampira indo ao seu estabelecimento na calada da noite...",
  "🌌 Viajando pelo espaço-tempo até o seu estabelecimento..."
];

const ParceiroPhrases = () => <>{phrases[Math.floor(Math.random() * phrases.length)]}</>;
  
export default ParceiroPhrases;