import { useAuth } from '@Contexts/Auth';
import { get } from "lodash";
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import OverlayLoading from '@Components/Loaders/OverlayLoading';
import SEO from '@Components/SEO';
import WebApi from '@Services/WebApi';
import Swal from '@Components/Layout/Swal';
import RandomPhrases from '@Components/RandomPhrases';

interface ComponentProps {
  children?: React.ReactNode
}

/**
 * Middleware para obter o parceiro se for um id de parceiro
 */
const GetParceiro = ({ children }: ComponentProps) => {
  const navigate = useNavigate();
  const { idParceiro } = useParams();
  const {
    user,
    rede,
    isSuperAdmin,
    parceiro,
    SetParceiro,
    SetFuncionalidades,
    Logout
  } = useAuth();

  //////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////////////
  const handleGetParceiro = () => {
    if (!user) { return; }

    WebApi.v2.get(`meu/parceiros/${idParceiro}`).then(({ data }) => {
      let { parceiro } = data;

      if (parceiro.status_atual.label === 'cancelado' && !isSuperAdmin) {
        console.clear();
        const parceiroAtivo = rede.find(item => item.status_atual !== 'cancelado');
        if (parceiroAtivo) {
          Swal.fire('Oops', 'O estabelecimento selecionado está cancelado, redirecionando para outro estabelecimento...', 'warning');
          return navigate(`/dashboard/${parceiroAtivo.id_parceiro}`);
        }

        return Logout({ title: 'Atenção', text: 'Você não possui estabelecimentos ativos, por favor, entre em contato com o suporte.', icon: 'warning' });
      }

      // Merge mais bonito dos dados...
      parceiro = {
        ...parceiro, ...{
          meta: parceiro.metadataV2,
          temp_export: parceiro.temp_export ?? [],
          integracoes: {
            botconversa: {
              is_using: parceiro?.botconversa?.is_using,
            },
          },
          tipo_programa: parceiro.promocao_atual.tipo_programa
        }
      }

      SetFuncionalidades({
        'pilotoAutomatico': get(parceiro, 'funcionalidades.automacao_ativo', true),
        'emailMarketing': get(parceiro, 'funcionalidades.email_mkt_ativo', true),
        'segmentacoesClientes': get(parceiro, 'funcionalidades.segmentacao_ativo', true),
        'ofertas': get(parceiro, 'funcionalidades.ofertas_ativo', true),
        'ofertasIndicacao': get(parceiro, 'funcionalidades.indicacao_clientes_ativo', true),
        'pesquisas': get(parceiro, 'funcionalidades.pesquisas_ativo', true),
        'whatsapp': get(parceiro, 'funcionalidades.whatsapp_ativo', true),
        'midiaIndoor': get(parceiro, 'funcionalidades.midia_indoor_ativo', true),
      });

      // Deleta o que não for usado
      delete parceiro.metadados;
      delete parceiro.metadata;
      delete parceiro.metadataV2;
      delete parceiro.roleta_config;
      delete parceiro.funcionalidades;

      SetParceiro(parceiro);


    }).catch(err => {
      throw err;
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////
  // Effects
  //////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    if (!parceiro || parceiro?.id_parceiro != idParceiro) {
      SetParceiro(false);
      handleGetParceiro();
    };
  }, [idParceiro])

  //////////////////////////////////////////////////////////////////////////////////////
  // Render
  //////////////////////////////////////////////////////////////////////////////////////
  return (<>
    {!parceiro && (<>
      <SEO prefix='Meu Fidelizi' title={'Carregando Parceiro'} />
      <OverlayLoading><RandomPhrases.Parceiro /></OverlayLoading>
    </>)}
    {parceiro && children}
  </>)
}

export default React.memo(Object.assign(GetParceiro, { displayName: 'GetParceiro' }));
