// configure axios
import { authCookie } from "@Contexts/Auth";
import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "../Cookies";


/*** Interceptor for every request */
const RequestInterceptorV2 = async (config) => {
    let token = getCookie(authCookie)
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
};

const RequestInterceptorV3 = async (config) => {
    let token = getCookie(authCookie)
    if (token) {
        config.headers.Authorization = `Bearer ad ${token}`;
    }

    return config;
};

/*** Interceptor for every success */
const ResponseSuccessInterceptor = (response) => {
    return response;
};

/*** Interceptor for every error */
const ResponseErrorInterceptor = (response) => {

    // Bad Request
    if (response.response?.status === 400) {
        console.warn("Bad Request");
    }

    // Unauthorized
    if (response.response?.status === 401) {
        console.warn("Unauthorized");
    }

    // Forbidden
    if (response.response?.status === 403) {
        console.warn("Forbidden");
    }
    // Not Found, redirect vanilla pra não gerar historicos nem nada
    if (response.response?.status === 404) {
        // window.location.href = '/';
    }

    // Internal Server Error
    if (response.response?.status === 500) {
        toast.error('Ocorreu um erro desconhecido, entre em contato com o suporte!', {
            position: "top-right",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastStyle: { backgroundColor: "crimson" }
        });
        console.warn("Internal Server Error");
    }

    return Promise.reject(response.response);
};

/**
 * =================================================================================
 */
const WebApi = {
    /**
     * Organização relacionada a WebApi versão 2
     */
    v2: axios.create({ baseURL: process.env.REACT_APP_URL_WEBAPI + "/api/v2", }),
    /**
     * Organização relacionada a WebApi versão 3
     */
    v3: axios.create({ baseURL: process.env.REACT_APP_URL_WEBAPI_V3 + "/api/v3", })
};

WebApi.v2.interceptors.request.use(RequestInterceptorV2);
WebApi.v2.interceptors.response.use(ResponseSuccessInterceptor, ResponseErrorInterceptor);

WebApi.v3.interceptors.request.use(RequestInterceptorV3);
WebApi.v3.interceptors.response.use(ResponseSuccessInterceptor, ResponseErrorInterceptor);

export default WebApi;
export { WebApi };

