const phrases = [
  "🧙‍♂️ Invocando seus dados mágicos...",
  "🦄 Pegando o unicórnio dos seus dados...",
  "🛸 Abduzindo suas informações...",
  "🧙‍♀️ Lendo sua bola de cristal...",
  "🧚‍♂️ Procurando suas informações com pó de fada...",
  "🧜‍♀️ Mergulhando nos seus dados...",
  "🦸‍♂️ Salvando suas informações do vilão...",
  "🧞‍♂️ Realizando o desejo dos seus dados...",
  "🧙‍♂️ Abracadabra! Achando seus dados...",
  "🐉 Trazendo seus dados do reino dos dragões...",
  "👽 Escaneando seus dados intergalácticos...",
  "🌟 Fazendo uma estrela cadente buscar suas informações...",
  "🧛‍♂️ Mordendo seus dados (prometo não doer)...",
  "🦸‍♀️ Heroína capturando seus dados...",
  "🧙‍♀️ Feitiço 'Aparecium Data' em ação...",
  "🧝‍♂️ Elfos trabalhando em suas informações...",
  "🧙‍♂️ Bruxa pesquisando no seu caldeirão...",
  "🧛‍♀️ Vampira pegando seus dados na calada da noite...",
  "🧜‍♂️ Tritão encontrando seus dados nas profundezas...",
  "🌌 Viajando pelo tempo para buscar suas informações...",
  "🧙‍♂️ Usando poções para achar seus dados...",
  "🧙‍♀️ Cartomante prevendo suas informações...",
  "🧞‍♂️ Seu gênio da lâmpada está buscando seus dados...",
  "🌠 Desejando suas informações a uma estrela...",
  "🧛‍♂️ Contando seus dados com mordidas precisas..."
];

const UsuarioPhrases = () => <>{phrases[Math.floor(Math.random() * phrases.length)]}</>;
  
export default UsuarioPhrases;