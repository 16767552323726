const RoutesPublicas = [
  {
    path: "/",
    component: "/Login",
    title: "Login",
    prefix: "Meu Fidelizi",
  },
  {
    path: "/suporte",
    redirect: "/?suporte",
  },
  {
    path: "/sebraeconnect",
    redirect:
      "https://wa.me/5519997105677?text=Olá%2C+vim+pelo+portal+do+Sebrae+e+gostaria+de+saber+mais%21",
  },
  {
    path: "/latest",
    redirect:
      "https://fidelizii.s3.sa-east-1.amazonaws.com/app/fidelizii_2.100_amazon.apk",
  },
  {
    path: "/login",
    redirect: "/",
  },
  {
    path: "reset",
    component: "/ResetPassword",
    title: "Resetar Senha",
  },
  {
    path: "*",
    component: "/Page404",
    title: "Página não encontrada",
    prefix: "Meu Fidelizi",
  },
  {
    path: "/campanha/resgate",
    component: "/ResgatePremio",
    title: "Ativação de Oferta",
  },
  {
    path: "/faq",
    redirect: "https://ajuda.fidelizii.com.br",
  },
  {
    path: "/faq/:categoria",
    redirect: "https://ajuda.fidelizii.com.br",
  },
  {
    path: "/faq/artigo/:artigo",
    redirect: "https://ajuda.fidelizii.com.br",
  },
  {
    path: "/academy",
    component: "/Academy",
    title: "Academy",
  },
  {
    path: "/academy/:slug",
    component: "/Academy",
    title: "Academy",
  },
  {
    path: "/preview-email/:hash",
    component: "/PreviewEmail",
    title: "Preview do Email",
  },
  {
    path: "/pesquisa/:token",
    component: "/Pesquisa",
    title: "Pesquisa",
  },
  {
    path: "/proposta/:id",
    component: "/Proposta",
    title: "Proposta",
  },
  {
    path: "/aprovacao-proposta/:idProposta",
    component: "/AceiteProposta",
    title: "Aprovação de Proposta",
  },
];

export default RoutesPublicas;
